<template>
  <div class="container">
    <video id="myVideo" class="videopreview" controls width="100%" height="100%" style="object-fit: contain;width:100%;height:100%">
      <source :src="url" type="application/x-mpegURL" />
    </video>
  </div>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-flash'
import 'videojs-contrib-hls'
export default {
  props: ['url'],
  data () {
    return {
      myVideo: null
    }
  },
  mounted () {
    videojs('myVideo', {
      bigPlayButton: false,
      textTrackDisplay: false,
      posterImage: true,
      errorDisplay: false,
      controlBar: true,
      controls: true
    }, function () {
      this.play()
      this.tech_.off('mouseup')
      this.tech_.on('mouseup', function (e) {
        e.preventDefault()
      })
    })
  },
  destroyed () {
    videojs('myVideo', {
      bigPlayButton: false,
      textTrackDisplay: false,
      posterImage: true,
      errorDisplay: false,
      controlBar: true
    }, function () {
      this.dispose()
    })
  }

}
</script>

<style scoped lang="less">
.container {
  width: 550px;
  height: 700px;
  /deep/.videopreview {
    .vjs-control-bar {
      display: none !important;
    }
  }
  /deep/ .vjs-control-text {
    display: none;
    /dep/ .vjs-control-text::after {
      display: none !important;
    }
  }
  /deep/::-webkit-scrollbar-thumb {
    border: none !important;
  }
  /deep/::-webkit-scrollbar-track {
    border: none !important;
  }
}

// .vjs-tech {
//   pointer-events: none;
// }
</style>
