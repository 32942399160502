import { render, staticRenderFns } from "./vediopreview.vue?vue&type=template&id=5ef1705e&scoped=true&"
import script from "./vediopreview.vue?vue&type=script&lang=js&"
export * from "./vediopreview.vue?vue&type=script&lang=js&"
import style0 from "./vediopreview.vue?vue&type=style&index=0&id=5ef1705e&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ef1705e",
  null
  
)

export default component.exports